<template>
  <div
      class="center"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
  >
    <div class="header flex">
      <div class="header_left flex">
        <div class="imgs">
          <img
              :src="basicList.imageurl"
              :alt="basicList.userName"
              :class="basicList.imageurl?'avatar':'err_avatar'"
              v-if="basicList.imageurl"
          />
          <img v-else src="~static/column/default.png" alt="默认头像" class="avatar err_avatar"/>
        </div>
        <ul class="info">
          <li class="flex">
            <h1>{{ basicList.userName }}</h1>
          </li>
          <li class="edu">
            <span>
              {{ basicList.sex }}
            </span>
            <span v-if="basicList.age">
               <span>/</span>
              {{ basicList.age }}岁
            </span>
            <span v-if="basicList.edu"><span>/</span>{{ basicList.edu }}</span>
            <span v-if="basicList.exp">{{ basicList.exp }}</span>
          </li>
          <li class="level" v-for="item in basicList.disabledType" :key="item.index">
            <span>
              {{ item.disabledType }}
              <span>/</span>
            </span>
            <span>
              {{ item.disabledLevel }}
              <span>/</span>
            </span>
            <span>{{ item.disabledDetail }}</span>
          </li>
          <li class="address">
            <span>户籍地：{{ basicList.provinceid }}{{ basicList.cityid }}</span>
          </li>
        </ul>
      </div>
      <ul class="header_right flex">
        <li>
          <h1>
            {{ countList.LOOKS || 0}}
            <small>家</small>
          </h1>
          <p>企业看过</p>
        </li>
        <li>
          <h1>
            {{ countList.FEEDS || 0}}
            <small>家</small>
          </h1>
          <p>企业反馈</p>
        </li>
        <li>
          <el-button
              @click="goRouter('/public/resume/',memberId,userType)"
              type="primary"
              plain
              size="mini"
          >查看简历
          </el-button>
          <el-button @click="refreshResume" type="primary" size="mini">刷新简历</el-button>
        </li>
      </ul>
    </div>
  <tips :pointKey="4"></tips>
    <ul class="header_content flex">
      <li>
        <h1>
          <router-link to="/center/work/?active=3" class="color00 marR10">{{ countList.INTERVIEWS || 0}}</router-link>
          <small>家</small>
        </h1>
        <p>邀请我的</p>
      </li>
      <li>
        <h1>
          <router-link to="/center/work/?active=0" class="color00 marR10"> {{ countList.APPLYS || 0}}</router-link>
          <small>家</small>
        </h1>
        <p>我投递的</p>
      </li>
      <li>
        <h1>
          <router-link to="/center/work/?active=3" class="color00 marR10">{{ countList.ARGEES || 0}}</router-link>
          <small>家</small>
        </h1>
        <p>接受邀请</p>
      </li>
      <li>
        <h1>
          <router-link to="/center/work/?active=5" class="color00 marR10">{{ countList.RECOMANDS }}</router-link>
          <small>家</small>
        </h1>
        <p>推荐给我的</p>
      </li>
      <li class="flex cursorP" @click="windowRouter()">
        <img src="~/static/center/banner.png" alt="残疾人测评"/>
        <div class="info">
          <h2>残疾人测评</h2>
          <a href="javascript:void(0);">
            去测评
            <i class="el-icon-d-arrow-right"></i>
          </a>
        </div>
      </li>
    </ul>
    <!-- 为你推荐 -->
    <div class="content">
      <div class="title">
        <h1>为你推荐</h1>
      </div>
      <ul
          class="list loads"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          v-if="status.isHide == 0"
      >
        <li class="box" v-for="item in recomdList" :key="item.id">
          <div class="top flex">
            <div class="left">
              <router-link :to="'/job/jobDetail/?id='+item.id" target="_blank">
                <h3>
                  {{ item.jobName }}
                  <small>「{{ item.updateTime }}」</small>
                </h3>
              </router-link>
              <p>
                <span class="money" v-if="item.jobSalary">{{ item.jobSalary }}</span>
                <span class="money" v-else>面议</span>
                <span v-if="item.provinceid">
                  {{ item.provinceid }}
                  <i></i>
                </span>
                <span v-else>
                  地区暂无
                  <i></i>
                </span>
                <span v-if="item.edu">
                  {{ item.edu }}
                  <i></i>
                </span>
                <span v-else>
                  学历暂无
                  <i></i>
                </span>
                <span v-if="item.exp">
                  {{ item.exp }}
                  <i></i>
                </span>
                <span v-else>
                  工作经验暂无
                  <i></i>
                </span>
                <span v-for="(n,i) in item.jobDistypeOutDTOList" :key="i">
                  {{ k.disType }}
                  <i v-if="k.disTypeLevel"></i>
                  {{ k.disTypeLevel }}
                </span>
              </p>
            </div>
            <div class="right flex" v-if="item.companyInfo">
              <div class="info">
                <router-link :to="'/job/businessDetails/?companyId='+item.companyId" target="_blank">
                  <h3 class="line_clamp1">{{ item.companyInfo.companyName }}</h3>
                </router-link>
                <p>
                  <span v-if="item.companyInfo.hyTop">
                    {{ item.companyInfo.hyTop }}
                    <i></i>
                  </span>
                  <span v-else>
                    行业类型暂无
                    <i></i>
                  </span>
                  <span v-if="item.companyInfo.pr">
                    {{ item.companyInfo.pr }}
                    <i></i>
                  </span>
                  <span v-else>
                    公司类型暂无
                    <i></i>
                  </span>
                  <span v-if="item.companyInfo.mun">
                    {{ item.companyInfo.mun }}
                    <i></i>
                  </span>
                  <span v-else>
                    要求人数若干
                    <i></i>
                  </span>
                </p>
              </div>
              <img
                  v-if="item.companyInfo.logo"
                  :src="item.companyInfo.logo"
                  :alt="item.companyInfo.companyName"
                  class="logo img"
                  style="overflow:hidden"
              />
              <img v-else :src="item.companyInfo.logo" alt="公司默认logo" style="overflow:hidden"
                   class="logo img err_logo"/>
            </div>
          </div>
          <div class="bottom flex">
            <div class="left" v-if="item.jobLabels.length>0">
              <span v-for="(n,i) in item.jobLabels" :key="i">{{ n.labelName }}</span>
            </div>
            <div class="left" v-else>
              <span>暂无福利标签</span>
            </div>
            <!-- <div class="right">
              <span>简历处理速度</span>
              <div class="schedule">
                <div :style="{width:item.companyInfo.vate+'%'}"></div>
              </div>
              <span v-if="item.companyInfo.vate">{{item.companyInfo.vate}}%</span>
              <span v-else>0%</span>
            </div>-->
          </div>
        </li>
      </ul>
      <status type="post" v-if="status.isHide == 1" des="暂无岗位推荐~"></status>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import tips from '../../components/public/tips.vue';

export default {
  components: { tips },
  name: "index",
  data() {
    return {
      value: "1",
      loading: false,
      userType: "",
      memberId: "",
      basicList: [],
      countList: [],
      recomList: [],
      recomdList: [],
      form: {
        width: "70",
      },
      status: {
        isHide: 0,
        type: "",
      },
      search: {
        pageNum: 1,
        pageSize: 5,
      },
    };
  },
  computed:{
    ...mapState(['userPassword']),
  },
  watch: {
    search: {
      handler(newName, oldName) {
        this.info();
      },
      deep: true,
    },
  },
  methods: {
    // 基本信息
    getMyDisAbledInfo() {
      // this.loading = true;
      this.$api
          .getMyDisAbledInfo()
          .then((res) => {
            if (res.data.success) {
              this.basicList = res.data.data;
              this.loading = false;
            } else {
              console.log("错误了", error);
              this.loading = false;
            }
          })
          .catch((error) => {
            console.log("错误了", error);
            this.loading = false;
          });
    },
    // 数量统计
    getDisabledCountInfo() {
      this.$api
          .getDisabledCountInfo()
          .then((res) => {
            if (res.data.success) {
              this.countList = res.data.data;
              this.loading = false;
            } else {
              console.log("错误了", error);
              this.loading = false;
            }
          })
          .catch((error) => {
            console.log("错误了", error);
          });
    },
    // 推荐简历
    querySearchJobInfo() {
      this.$api
          .queryAboutHopeJob(this.search)
          .then((res) => {
            this.loading = true;
            this.recomdList = res.data.data;
            this.loading = false;
            if (this.recomdList.length <= 0) {
              this.status.isHide = 1;
              this.status.type = "result";
            } else {
              this.status.isHide = 0;
            }
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
            this.status.isHide = 1;
            this.status.type = "wifi";
          });
    },
    // 刷新简历
    async refreshResume() {
      let res = await this.$api.refreshResume();
      console.log(res);
      if (res.data.success) {
        this.$message.success("刷新成功");
      }
    },
    goRouter(url, memberId, userType) {
      const {href} = this.$router.resolve({
        path: url,
        query: {
          id: memberId,
          userType: userType,
        },
      });
      window.open(href, "_blank");
    },
    windowRouter() {
      window.open('https://cjrcp.webtrn.cn/zcl_zycs/zcl/general/homePage/homePage.html');
    },
    open() {
        this.$confirm('您的密码过于简单，为了您账号安全，请尽快修改密码。', '提示', {
          confirmButtonText: '去修改',
          cancelButtonText: '',
          type: 'warning',
          showCancelButton:false,
          showClose:false,
        }).then(() => {
          this.$router.push('/settingPass/')
        }).catch(() => {
        });
      },
  },
  created() {
    this.getMyDisAbledInfo();
    this.getDisabledCountInfo();
    this.querySearchJobInfo();
    let userType = this.$store.state.userInfo || JSON.parse(localStorage.getItem('userInfo'));
    if (userType) {
      this.memberId = userType.id;
      this.userType = userType.userType;
    }
  },
  mounted(){
    if(this.userPassword == '123456'){
      this.open();
    }
  }
};
</script>

<style scoped lang="less">
@import "~assets/css/center/center.less";

/deep/ .el-loading-spinner {
  top: 12%;
}
/deep/.tips{
  width: 100%;
}
</style>
